<script setup lang="ts"></script>

<template>
    <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.225001 10.5365L4.52 14.7757C5.00417 15.254 5.8325 14.9148 5.8325 14.239L5.8325 11.2498L14.75 11.2498C15.44 11.2498 16 10.6907 16 9.99984C16 9.309 15.44 8.74984 14.75 8.74984L5.83333 8.74984L5.83333 5.76067C5.83333 5.08484 5.005 4.74567 4.52083 5.224L0.225832 9.46317C-0.0741668 9.75984 -0.0749984 10.2398 0.225001 10.5365Z"
            fill="currentColor"
        />
    </svg>
</template>
